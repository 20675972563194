.wp-post {
    font-size: var(--chakra-fontSizes-xl);
    line-height: 1.6;
}

.wp-post p,
.wp-post h2 {
    margin-top: var(--chakra-sizes-4);
    margin-bottom: var(--chakra-sizes-4);
}

.wp-post h2 {
    margin-top: var(--chakra-sizes-8);
    font-weight: 300;
    font-size: 1.75rem;
}

.wp-post a {
    color: var(--chakra-colors-themeGreen-500)
}

.wp-post a:hover {
    text-decoration: underline;
}

.wp-post li {
    margin-top: var(--chakra-sizes-2);
    margin-bottom: var(--chakra-sizes-2);
}

.wp-post>ul {
    margin-left: 2em;
}

.wp-post ul ul {
    margin-left: 2em;
}

.wp-post ul ul ul {
    margin-left: 2em;
}

.wp-post ul ul ul ul {
    margin-left: 2em;
}

.wp-post ul ul ul ul ul {
    margin-left: 2em;
}

.wp-post>ol {
    margin-left: 2em;
}

.wp-post ol ol {
    margin-left: 2em;
}

.wp-post ol ol ol {
    margin-left: 2em;
}

.wp-post ol ol ol ol {
    margin-left: 2em;
}

.wp-post ol ol ol ol ol {
    margin-left: 2em;
}

.wp-post blockquote {
    border-left: 1px solid;
    padding-left: var(--chakra-space-4);
}

.wp-post cite {
    font-size: var(--chakra-fontSizes-md);
}

.wp-post img {
    padding-top: var(--chakra-space-8);
    padding-bottom: var(--chakra-space-8);
}

.wp-post .wp-block-buttons {
    margin-top: var(--chakra-space-8);
    margin-bottom: var(--chakra-space-8);
}

.wp-post .wp-block-buttons .wp-block-button {
    background-color: var(--chakra-colors-themeGreen-500);
    padding:  var(--chakra-space-2);
    padding-left:  var(--chakra-space-4);
    padding-right:  var(--chakra-space-4);
    display: inline-flex;
    border-radius: var(--chakra-space-2);
    color:white;
}

.wp-post .wp-block-buttons .wp-block-button a {
    color:white;
}
:root {
    --wp-block-synced-color: #7a00df;
    --wp-block-synced-color--rgb: 122, 0, 223
}

.wp-block-image img {
    height: auto;
    max-width: 100%;
    vertical-align: bottom
}

.wp-block-image.has-custom-border img,
.wp-block-image img {
    box-sizing: border-box
}

.wp-block-image.aligncenter {
    text-align: center
}

.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
    height: auto;
    width: 100%
}

.wp-block-image.aligncenter,
.wp-block-image .aligncenter,
.wp-block-image.alignleft,
.wp-block-image .alignleft,
.wp-block-image.alignright,
.wp-block-image .alignright {
    display: table
}

.wp-block-image.aligncenter>figcaption,
.wp-block-image .aligncenter>figcaption,
.wp-block-image.alignleft>figcaption,
.wp-block-image .alignleft>figcaption,
.wp-block-image.alignright>figcaption,
.wp-block-image .alignright>figcaption {
    caption-side: bottom;
    display: table-caption
}

.wp-block-image .alignleft {
    float: left;
    margin: .5em 1em .5em 0
}

.wp-block-image .alignright {
    float: right;
    margin: .5em 0 .5em 1em
}

.wp-block-image .aligncenter {
    margin-left: auto;
    margin-right: auto
}

.wp-block-image figcaption {
    margin-bottom: 1em;
    margin-top: .5em
}

.wp-block-image.is-style-circle-mask img,
.wp-block-image.is-style-rounded img,
.wp-block-image .is-style-rounded img {
    border-radius: 9999px
}

@supports ((-webkit-mask-image:none) or (mask-image:none)) or (-webkit-mask-image:none) {
    .wp-block-image.is-style-circle-mask img {
        border-radius: 0;
        -webkit-mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
        mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
        mask-mode: alpha;
        -webkit-mask-position: center;
        mask-position: center;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain
    }
}

.wp-block-image :where(.has-border-color) {
    border-style: solid
}

.wp-block-image :where([style*=border-top-color]) {
    border-top-style: solid
}

.wp-block-image :where([style*=border-right-color]) {
    border-right-style: solid
}

.wp-block-image :where([style*=border-bottom-color]) {
    border-bottom-style: solid
}

.wp-block-image :where([style*=border-left-color]) {
    border-left-style: solid
}

.wp-block-image :where([style*=border-width]) {
    border-style: solid
}

.wp-block-image :where([style*=border-top-width]) {
    border-top-style: solid
}

.wp-block-image :where([style*=border-right-width]) {
    border-right-style: solid
}

.wp-block-image :where([style*=border-bottom-width]) {
    border-bottom-style: solid
}

.wp-block-image :where([style*=border-left-width]) {
    border-left-style: solid
}

.wp-block-image figure {
    margin: 0
}

:root {
    --wp-block-synced-color: #7a00df;
    --wp-block-synced-color--rgb: 122, 0, 223
}

.wp-block-image figcaption {
    color: #555;
    font-size: 13px;
    text-align: center
}

.is-dark-theme .wp-block-image figcaption {
    color: hsla(0, 0%, 100%, .65)
}

.wp-block-image {
    margin: 0 0 1em
}

.wp-block-image figcaption {
    color: #555;
    font-size: 13px;
    text-align: center
}

.is-dark-theme .wp-block-image figcaption {
    color: hsla(0, 0%, 100%, .65)
}

.wp-block-image {
    margin: 0 0 1em
}

.aligncenter {
    margin-right: auto;
    margin-left: auto;
}
